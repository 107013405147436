import { useEffect, useContext } from 'react';
import AdCategoriesContext from 'context/AdCategoriesContext';

// see https://developers.google.com/tag-manager/devguide

const useAds = ({
  id,
  sizes,
  sizeMap,
  position = 'atf',
  googletag = false, // for testing only
}) => {
  const context = useContext(AdCategoriesContext);

  let correctedAdUnitPath;
  try {
    const primaryCategoryArr = context.primaryCategory.split('/');
    correctedAdUnitPath =
      typeof context.primaryCategory == 'string'
        ? `/22546217694/mpr.news/${primaryCategoryArr[
            primaryCategoryArr.length - 1
          ]
            .replaceAll('-', '_')
            .slice(0, 40)}`
        : '/22546217694/mpr.news';
  } catch {
    correctedAdUnitPath = '/22546217694/mpr.news';
  }

  useEffect(() => {
    if (window.googletag && window.googletag.apiReady) {
      googletag = googletag || window.googletag;
      let slotId;
      googletag.pubads().collapseEmptyDivs();
      slotId = googletag
        .defineSlot(correctedAdUnitPath, sizes, id)
        // .defineSizeMapping(builtMapping)
        .addService(googletag.pubads())
        .setTargeting('pos', position)
        .setTargeting('primaryCategory', context?.primaryCategory);

      googletag.cmd.push(function () {
        const adMapping = googletag.sizeMapping();
        for (const [screenWidth, adSize] of Object.entries(sizeMap)) {
          adMapping.addSize([parseInt(screenWidth), 0], adSize);
        }
        // const builtMapping = adMapping.build();

        if (
          Array.isArray(context?.adCategories) &&
          context?.adCategories.length > 0 &&
          typeof context.adCategories[0] != 'undefined'
        ) {
          slotId.setTargeting(
            'categories',
            context?.adCategories.map((cat) => {
              return cat.slice(0, 40).replaceAll('-', '_');
            })
          );
        }

        googletag.pubads().enableLazyLoad();
        googletag.pubads().disableInitialLoad();

        googletag.enableServices();
        googletag.display(id);
        googletag.pubads().refresh([slotId]);
      });

      return () => {
        googletag.cmd.push(function () {
          googletag.destroySlots([slotId]);
        });
      };
    }
  }, [context]);
};

export default useAds;
