import React, {
  FC,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import menuData from './menuData';
import IconChevron from 'components/Icons/IconChevron';

interface Props {
  selfCloser?: Dispatch<SetStateAction<boolean>>;
  initialMenu?: 'main' | 'ways-to-give' | 'user-account';
  hasSubmenus?: boolean;
  largeMenu?: boolean;
}

const Menu: FC<Props> = ({
  selfCloser,
  initialMenu = 'main',
  hasSubmenus = true,
  largeMenu = false,
}) => {
  /* If the focused element is removed, the whole <body> will be focused instead.
    This is very bad for accessibility, so we need separate states for each menu
    so the next one can be focused before the current one is closed. The purpose
    of whichMenu is just so useEffect knows what to do. */
  const [whichMenu, setWhichMenu] = useState(
    initialMenu as 'main' | 'ways-to-give' | 'user-account'
  );
  const [mainMenuOpen, setMainMenuOpen] = useState(initialMenu === 'main');
  const [waysToGiveMenuOpen, setWaysToGiveMenuOpen] = useState(
    initialMenu === 'ways-to-give'
  );
  const [userAccountMenuOpen, setUserAccountMenuOpen] = useState(
    initialMenu === 'user-account'
  );

  const mainTopLink = useRef<HTMLAnchorElement>(null);
  const waysToGiveHeading = useRef<HTMLButtonElement>(null);
  const userTopLink = useRef<HTMLAnchorElement>(null);

  const router = useRouter();

  function openMain() {
    setWhichMenu('main');
    setMainMenuOpen(true);
    setWaysToGiveMenuOpen(false);
    setUserAccountMenuOpen(false);
  }

  function openWaysToGive() {
    setWhichMenu('ways-to-give');
    setWaysToGiveMenuOpen(true);
    setMainMenuOpen(false);
    setUserAccountMenuOpen(false);
  }

  useEffect(() => {
    if (whichMenu === 'main') {
      mainTopLink.current?.focus();
    }
    if (whichMenu === 'ways-to-give') {
      waysToGiveHeading.current?.focus();
    }
    if (whichMenu === 'user-account') {
      userTopLink.current?.focus();
    }
  }, [mainMenuOpen]);

  return (
    <>
      {mainMenuOpen && (
        <div
          className={`menu ${
            largeMenu && !waysToGiveMenuOpen ? 'menu-large' : ''
          }`}
        >
          {menuData.map((list, listI) => {
            return (
              <section key={list.name}>
                <div id={`${listI}-list-label`} className="menu-label">
                  {list.name}
                </div>
                <ul
                  className="menu-list"
                  aria-labelledby={`${listI}-list-label`}
                >
                  {list.items.map((item, i) => {
                    return (
                      <li key={`${i}-${item.name}-${item.url}`}>
                        <Link
                          href={item.url}
                          passHref
                          onClick={() => {
                            selfCloser && selfCloser(false);
                          }}
                          ref={listI === 0 && i === 0 ? mainTopLink : undefined}
                          target={
                            item.url.charAt(0) === '/' ? undefined : '_blank'
                          }
                          aria-label={
                            item.url.charAt(0) === '/'
                              ? undefined
                              : `${item.name} - Opens in a new tab`
                          }
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                  {list.name === 'About Us' && (
                    <li>
                      <button
                        onClick={openWaysToGive}
                        className="menu-item-higlighted"
                      >
                        Ways to Give
                        <IconChevron
                          direction="right"
                          color="var(--color-white)"
                          title="(opens submenu)"
                        />
                      </button>
                    </li>
                  )}
                </ul>
              </section>
            );
          })}
        </div>
      )}

      {waysToGiveMenuOpen && (
        <ul
          className={`menu menu-list ${
            largeMenu && !waysToGiveMenuOpen && 'menu-large'
          }`}
        >
          {hasSubmenus && (
            <li>
              <button
                onClick={openMain}
                className="menu-item-header"
                aria-label="Ways to Give - Click to go back to previous menu"
                ref={waysToGiveHeading}
              >
                <IconChevron
                  direction="left"
                  color="var(--color-mpr-charcoal)"
                  decorative
                />
                Ways to Give
              </button>
            </li>
          )}
          <li>
            <Link
              href="https://support.mpr.org/news-web"
              passHref
              target="_blank"
              aria-label="Start a Monthly Gift - Opens in a new tab"
            >
              Start a Monthly Gift
            </Link>
          </li>
          <li>
            <Link href="/waystogive" passHref>
              More Ways to Give
            </Link>
          </li>
          <li>
            <Link
              href="https://www.mpr.org/support/donate-vehicle"
              passHref
              target="_blank"
              aria-label="Donate a Vehicle - Opens in a new tab"
            >
              Donate a Vehicle
            </Link>
          </li>
          <li>
            <Link href="/waystogive#h2_planned_giving" passHref>
              Planned Giving
            </Link>
          </li>
        </ul>
      )}
      {userAccountMenuOpen && (
        <ul
          className={`menu menu-list menu-user ${
            largeMenu && !waysToGiveMenuOpen && 'menu-large'
          }`}
        >
          <li>
            <Link
              href="/for-you"
              ref={mainTopLink}
              onClick={() => {
                selfCloser && selfCloser(false);
              }}
            >
              For You
            </Link>
          </li>
          <li>
            <Link
              href="/profile"
              passHref
              onClick={() => {
                selfCloser && selfCloser(false);
              }}
            >
              Settings
            </Link>
          </li>
          <li>
            <button
              className="header-user"
              onClick={() => {
                selfCloser && selfCloser(false);
                router.push('/auth/signout');
              }}
            >
              Sign out
            </button>
          </li>
        </ul>
      )}
    </>
  );
};

export default Menu;
