import App from 'next/app';
import React from 'react';
import NowPlayingClient from 'nowplaying-client-2';
import { AudioPlayerProvider } from 'context/AudioPlayerContext';
import Layout from '../layouts/Layout';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { FavoritesContextProvider } from 'context/FavoritesContext';
import 'regenerator-runtime/runtime';
import { PotlatchNotificationProvider } from '../context/PotlatchNotificationContext';
import { AdCategoriesContextProvider } from 'context/AdCategoriesContext';
import {
  findPrimaryCollectionSlug,
  findCollectionsSlugs,
} from '../utils/findPrimaryCategory';
import 'public/css/styles.css';

class MPRNews extends App {
  constructor(props) {
    super(props);
  }

  loadPlayer = () => {
    return null;
  };

  setNowPlayingTitle(schedule) {
    let title = schedule[0].shows[0].name;
    if (schedule[0].people[0]) {
      title += ` with ${schedule[0].people[0].name}`;
    }
    return title;
  }

  setNowPlayingThumbnail(songdata) {
    let thumbnail = songdata.art_url;
    if (songdata.art_url) {
      thumbnail += ` with ${songdata.art_url}`;
    }
    return thumbnail;
  }

  setupNowPlaying = () => {
    const self = this;
    const client = new NowPlayingClient({
      server: 'wss://nowplayingv2.publicradio.org',
    });
    const registrations = [];
    const service = 'mpr-news';

    // Register the callback for a playlist change.
    const schedule_registration = client.register_callback(
      service,
      'schedule',
      function (data) {
        if (self.state.isAudioLive) {
          self.setState(
            {
              nowPlayingTitle: self.setNowPlayingTitle(data.schedule),
            },
            () => {
              self.setState({ audioTitle: self.state.nowPlayingTitle });
            }
          );
        }
      }
    );
    // Add the registration object to the array of registrations.
    registrations.push(schedule_registration);
  };

  render() {
    const { Component, pageProps } = this.props;

    const slug = findPrimaryCollectionSlug(pageProps);
    const collections = findCollectionsSlugs(pageProps);

    return (
      <>
        <Script strategy="beforeInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          if("${pageProps?.session?.user?.id}" != 'undefined') {
            dataLayer.push({ event: 'sessionView', user_id: "${pageProps?.session?.user?.id}"})
          }
            `}
        </Script>
        <Script>
          {`
          
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KTT2Z2');
          `}
        </Script>

        <Script strategy="lazyOnload">
          {`
(function(d, u, ac) {
	var s = d.createElement('script');
	s.type = 'text/javascript';
	s.src = 'https://a.omappapi.com/app/js/api.min.js';
	s.async = true;
	s.dataset.user = u;
	s.dataset.account = ac;
	d.getElementsByTagName('head')[0].appendChild(s);
})(document, 108635, 120426);
`}
        </Script>
        <SessionProvider session={pageProps?.session}>
          <AudioPlayerProvider>
            <FavoritesContextProvider>
              <PotlatchNotificationProvider>
                <AdCategoriesContextProvider
                  primaryCategory={slug}
                  adCategories={collections}
                >
                  <Layout
                    layout={pageProps?.layout || 'home'}
                    resourceType={
                      pageProps?.resourceType ? pageProps?.resourceType : 'page'
                    }
                  >
                    <Component {...pageProps} />
                  </Layout>
                </AdCategoriesContextProvider>
              </PotlatchNotificationProvider>
            </FavoritesContextProvider>
          </AudioPlayerProvider>
        </SessionProvider>
      </>
    );
  }
}

export default MPRNews;
