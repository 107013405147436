export const findPrimaryCollectionSlug = (pageProps) => {
  let key, slug;
  if (!pageProps.data) return 'ros';
  key = Object.keys(pageProps?.data)[0];

  switch (pageProps.resourceType) {
    case 'collection':
      slug = pageProps.data[key].canonicalSlug;
      break;
    case 'story':
    case 'episode':
      slug = pageProps.data[key]?.primaryCollection?.canonicalSlug ? pageProps.data[key].primaryCollection?.canonicalSlug : 'ros' ;
      break;
    case 'weather':
      slug = 'weather'
      break;
    case 'homepage':
      slug = 'homepage'
      break;
    case 'talking-sense':
      slug = pageProps.resourceType;
      break;
    default: 
      slug = 'ros'
  }

  return slug;
};

export const findCollectionsSlugs = (pageProps) => {
  let key;
  const collections = [];
  if (!pageProps.data) return 'ros';
  key = Object.keys(pageProps?.data)[0];

  try {
    pageProps.data[key].collections.forEach(coll => {
      collections.push(coll.canonicalSlug)
    })
  } catch {
    return []
  }
  
  return collections;

};
